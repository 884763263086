export enum OpType {
    List,
    Action
}

export class Operation {
    static readonly list = new Operation('list', 'Anagrafica', OpType.List);
    static readonly dati = new Operation('dati', 'Dati', OpType.Action);
    static readonly delete = new Operation('delete', 'Delete', OpType.Action);
    static readonly report = new Operation('report', 'Report', OpType.Action);
    static readonly export = new Operation('export', 'Export', OpType.Action);

    private constructor(public readonly key: string, public readonly description: string, public readonly type: OpType) {}

}
