import React from 'react';

import { LoginButton } from './login-button';

export const Login: React.FC = () => {
    return (
        <div className="bg-gradient-primary w-100 h-100">
            <div className="container w-100 h-100">
                <div className="row justify-content-center align-items-center w-100 h-100">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-1">Benvenuto</h1>
                                                
                                            </div>
                                            <LoginButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
