import { BlobServiceClient, BlockBlobClient, ContainerClient, BlobUploadCommonResponse } from '@azure/storage-blob';

import { Api } from './api';
import { FILE_LIST, VERIFY_CSV, IMPORT_CSV, SAS_SIGNATURE, EXPORT_CSV } from '../config';

import partnerService from './partner';

import { SASResponse } from '../models/sas-response';
import { File } from '../models/file';
import { CSVValidationResult, CSVProcessResult, CSVDeleteResult } from '../models/csv';
import { BlobFileRequest, BlobContainerRequest, BlobStorageRequest } from '../models/blob';

export class FileApi extends Api {
    public async getFiles(operation: string): Promise<File[]> {
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }

        const result = await this.get<File[]>(FILE_LIST, { params: { operation, partner: partnerService.queryId } });

        return result.data;
    }

    public async validate(operation: string, filename: string): Promise<CSVValidationResult> {
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }

        const result = await this.get<CSVValidationResult>(VERIFY_CSV, { params: { operation, filename, partner: partnerService.queryId } });

        return result.data;
    }

    public async process(operation: string, filename: string, sendemails: boolean): Promise<CSVProcessResult> {
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }

        const result = await this.get<CSVProcessResult>(IMPORT_CSV, { params: { operation, filename, partner: partnerService.queryId, sendemails } });

        return result.data;
    }

    
    public async processDelete(operation: string, filename: string): Promise<CSVDeleteResult> { 
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }
        const result = await this.delete<CSVDeleteResult>(FILE_LIST, { params: { operation, filename, partner: partnerService.queryId } });
        return result.data;
    }


    public async upload(operation: string, files: FileList): Promise<BlobUploadCommonResponse> {
        const signature = await this.getSignature(operation);

        // limit to 1 file for now
        const file = files[0];

        const request: BlobFileRequest = {
            filename: file.name,
            containerName: signature.containerName, 
            storageUri: signature.storageulr,
            storageAccessToken: signature.storageAccesToken
        };

        const blockBlobClient = this.getBlockBlobClient(request);
        return await blockBlobClient.uploadBrowserData(file, {
            blobHTTPHeaders: {
                blobContentType: file.type
            }
        });
    }

    public async export(operation: string): Promise<unknown> {
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }

        const result = await this.get<unknown>(EXPORT_CSV, { params: { operation, partner: partnerService.queryId } });

        return result.data;
    }


    public async report(operation: string): Promise<unknown> {
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }

        const result = await this.get<unknown>(EXPORT_CSV, { params: { operation, partner: partnerService.queryId } });

        return result.data;
    }

    private async getSignature(operation: string): Promise<SASResponse> {
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }

        const result = await this.get<SASResponse>(SAS_SIGNATURE, { params: { operation, partner: partnerService.queryId } });

        return result.data;
    }

    private getBlockBlobClient(request: BlobFileRequest): BlockBlobClient {
        const containerClient = this.getContainerClient(request);
        return containerClient.getBlockBlobClient(request.filename);
    }

    private getContainerClient(request: BlobContainerRequest): ContainerClient {
        const blobServiceClient = this.buildClient(request);
        return blobServiceClient.getContainerClient(request.containerName);
    }

    private buildClient(options: BlobStorageRequest): BlobServiceClient {
        return BlobServiceClient.fromConnectionString(this.buildConnectionString(options));
    }

    private buildConnectionString = (options: BlobStorageRequest): string => {
        return `BlobEndpoint=${options.storageUri};SharedAccessSignature=${options.storageAccessToken}`;
    };
}
