import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import partnerService from '../../services/partner';
import { FileApi } from '../../services/file';

import { File } from '../../models/file';
import { OpType } from '../../models/operation';

import { formatDate } from '../../utils';

import { Import } from './import';

export const Files: React.FC = () => {
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [operation, setOperation] = useState<string>('');
    const [files, setFiles] = useState<File[]>();
    const [currentFile, setCurrentFile] = useState<File>();

    const api = new FileApi();

    const fetchFiles = async (op: string): Promise<void> => {
        try {
            const files = await api.getFiles(op);
            setFiles(files);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const changeOperation = async (event: ChangeEvent<HTMLSelectElement>): Promise<void> => {
        event.preventDefault();

        setOperation(event.target.value);

        fetchFiles(event.target.value);
    };

    const uploadFiles = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.preventDefault();

        if (operation && event.target.files) {
            try {
                await api.upload(operation, event.target.files);
                fetchFiles(operation);

                toast.success('File caricato con successo');
            } catch (e) {
                toast.error(e.message);
            }
        }
    };

    const showFileDialog = (): void => {
        inputFileRef.current && inputFileRef.current.click();
    };

    const reloadFileList = (): void => {
        fetchFiles(operation);
    };

    const listOperations = partnerService.operations.filter(op => op.type === OpType.List);
    useEffect(() => {
        if (listOperations && listOperations.length === 1) {
            setOperation(listOperations[0].key);
            fetchFiles(listOperations[0].key);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Movimenti</h1>
            <p className="mb-4">Console di caricamento dei file CSV</p>

            {listOperations && listOperations.length > 1 && (
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-green">Seleziona tipo di operazione:</h6>
                    </div>
                    <div className="card-body">
                        <select name="type" className="form-control" value={operation} onChange={changeOperation}>
                            <option value="" disabled>
                                Seleziona operazione...
                            </option>
                            {listOperations.map(op => (
                                <option key={op.key} value={op.key}>
                                    {op.description}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            {files && (
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-green">Elenco file</h6>
                                <div className="float-right">
                                    <input style={{ display: 'none' }} ref={inputFileRef} type="file" multiple={true} accept=".csv" onChange={uploadFiles} />,
                                    <button className="btn btn-success" onClick={(): void => showFileDialog()}>
                                        <i className="fas fa fa-plus"></i>
                                    </button>
                                    <button className="btn btn-info ml-1" onClick={(): void => reloadFileList()}>
                                        <i className="fas fa fa-sync"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Nome file</th>
                                                <th scope="col">Data</th>
                                                <th scope="col">Stato</th>
                                                <th scope="col">Azione</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {files.length > 0 &&
                                                files.map(file => (
                                                    <tr className="table-row" key={`order_${file.FileName}`}>
                                                        <td>{file.FileName}</td>
                                                        <td>{formatDate(file.ModifiedDate)}</td>
                                                        <td>{file.State}</td>
                                                        <td>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={(): void => setCurrentFile(file)}
                                                                data-toggle="modal"
                                                                data-target="#validateModal"
                                                            >
                                                                Azioni
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Import id="validateModal" filename={currentFile?.FileName} closeAction={(): void => setCurrentFile(undefined)} />
        </>
    );
};
