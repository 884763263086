import React, { Dispatch, SetStateAction } from 'react';

import { UserWithPoints } from '../../../models/user';
import { formatDate } from '../../../utils';

import { Modal } from '../../_shared/modal';

type ModalProps = {
    user: UserWithPoints | undefined;
    closeAction: Dispatch<SetStateAction<void>>;
};

export const PointsUserDetails: React.FC<ModalProps> = (props: ModalProps) => {
    return (
        <Modal id="userModal" title="Dettagli utente" size="modal-lg" closeAction={props.closeAction}>
            <div className="table-responsive">
                <table className="table-borderless w-100">
                    <tbody>
                        <tr>
                            <td>
                                <strong>
                                    {props.user?.Name} {props.user?.FamilyName}
                                </strong>
                                <br />
                                Email:{' '}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            props.user?.Emails.map(e => {
                                                return `<a href="mailto:${e}">${e}</a>`;
                                            }).join(', ') || ''
                                    }}
                                ></span>
                                <br />
                                Tel: {props.user?.Telefono}
                            </td>
                            <td>
                                <strong>{props.user?.RagioneSociale}</strong>
                                <br />
                                P.IVA: {props.user?.PartitaIva}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className="user-access">
                                    Iscritto in data: {formatDate(props.user?.createdAt)}
                                    <br />
                                    Ultimo accesso: {formatDate(props.user?.LastLogin)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {props.user?.History?.Movimenti && (
                    <>
                        <hr />
                        <h5 className="mt-2">Movimenti</h5>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Data</th>
                                    <th scope="col">Descrizione</th>
                                    <th scope="col">Valore</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.user?.History?.Movimenti?.map(movement => (
                                    <tr key={movement._id}>
                                        <td>{formatDate(movement.Datamovimento)}</td>
                                        <td>{movement.Descrizionemovimento}</td>
                                        <td className="importo">{movement.Valore}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={2} className="saldo">Data saldo: {formatDate(props.user?.History?.DataSaldo)}</td>
                                    <td className="importo saldo">Saldo: {props.user?.History?.Saldo}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </Modal>
    );
};
