import dayjs from 'dayjs';
import 'dayjs/locale/it';

const formatMoney = (amount: number, decimalCount = 2, decimal = ',', thousands = '.'): number | string | undefined => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        const i = parseInt(Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        const j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - parseInt(i))
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        );
    } catch (e) {
        console.log(e);
    }
};

const formatDate = (date: string | number | Date | undefined): string => {
    const formatted = dayjs(date).locale('it').format('DD MMMM YYYY HH:mm'); 
    return formatted;
};

const formatDateShort = (date: string | number | Date | undefined): string => {
    const formatted = dayjs(date).locale('it').format('DD_MM_YYYY_HH:mm'); 
    return formatted;
};

export { formatMoney, formatDate, formatDateShort };
