import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { LogLevel, Logger } from 'msal';
import { getAuthorityConf } from '../config';

const configuration = getAuthorityConf();


const logger = new Logger(
    (logLevel, message, containsPii) => {
        console.log(logLevel, '[MSAL]', message);
        console.log('[MSAL Pii]', containsPii);
    },
    {
        level: LogLevel.Error,
        piiLoggingEnabled: true
    }
);

export const appConfig = {
    scopes: ['https://wlbackoffice.azurewebsites.net/Backoffice.Access']
};

// request to acquire a token for resource access
export const tokenRequest = {
    scopes: appConfig.scopes
};

export const authProvider = new MsalAuthProvider(
    {
        auth: {
            clientId: configuration.CLIENT_ID,
            authority: configuration.AUTHORITY_LOGIN,
            postLogoutRedirectUri: window.location.origin,
            redirectUri: configuration.LOGIN_REDIRECT_URI,
            navigateToLoginRequestUrl: false
        },        
        system: {
            logger: logger
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false
        }
    },
    {
        scopes: appConfig.scopes
    },
    {
        loginType: LoginType.Redirect,
        tokenRefreshUri: window.location.origin
    }
);
