import React, { ReactElement, FC } from 'react';
import "./style.css";

type ToggleProps = {
  setCheck: any;
  checked: boolean;
}

const ToggleSwitch: FC<ToggleProps> = (props: ToggleProps): ReactElement => {
  
  const onChange = (event: any): void => {
    event.persist();
    props.setCheck(event.target.checked);
  };

  return (
    <div className="checkbox-row">
       <label htmlFor="idsendemail" className="checkbox-row__label">
        Selezionare per inviare le notifiche email: 
      </label>
      <input className="switch" id="idsendemail" type="checkbox" onChange={onChange} checked={props.checked} />
     
    </div>
  )
};
  
  export default ToggleSwitch;
