import React, { ReactNode } from 'react';

import { LeftMenu } from './left-menu';
import { TopMenu } from './top-menu';

type LayoutProps = {
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    return (
        <>
            <LeftMenu />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <TopMenu />
                    <div className="container-fluid">{props.children}</div>
                </div>
            </div>
        </>
    );
};
