import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiConfig } from './api.config';

import { authProvider, tokenRequest } from '../providers/auth-provider';

export class Api {
    private api: AxiosInstance;

    public constructor() {
        this.api = axios.create(apiConfig);

        this.api.interceptors.request.use(async (param: AxiosRequestConfig) => {
            document.getElementById('wish-loader')?.classList.add('show');
            const token = await this.getToken();
            param.headers.Authorization = `Bearer ${token}`;

            return {
                ...param
            };
        });

        this.api.interceptors.response.use((param: AxiosResponse) => {
            document.getElementById('wish-loader')?.classList.remove('show');
            return {
                ...param
            };
        });
    }

    public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.get(url, config);
    }

    public post<T, R = AxiosResponse<T>>(url: string, data?: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.post(url, data, config);
    }

    
    public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.delete(url, config);
    }

    private async getToken(): Promise<string | undefined> {
        try {
            const token = await authProvider.acquireTokenSilent(tokenRequest);
            return token.accessToken;
        } catch (e) {
            //authProvider.acquireTokenPopup(tokenRequest);
            authProvider.acquireTokenRedirect(tokenRequest);
        }
    }
}
