import React from 'react';

import { TopMenuAccount } from './account';

export const TopMenu: React.FC = () => {
    return (
        <nav className="navbar navbar-expand navbar-light bg-custom-dark topbar mb-4 static-top shadow">
            <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>
                <TopMenuAccount />
            </ul>
        </nav>
    );
};
