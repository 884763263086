import React from 'react';

export const Loader: React.FC = () => {
    return (
        <div id="wish-loader" className="spinner-bg">
            <div className="spinner-border text-info" style={{ width: '3rem', height: '3rem' }} role="status">
                <span className="sr-only">Caricamento...</span>
            </div>
        </div>
    );
};
