import { Operation } from './operation';

export enum PartnerFactory {
    Revenue,
    Point
}

export type Partner = {
    name: string;
    queryId: string;
    operations: Operation[];
    factory: PartnerFactory;
    image?: string;
};
