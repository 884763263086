import React, { Dispatch, SetStateAction, ReactNode } from 'react';

type ModalProps = {
    id: string;
    title: string;
    size?: string;
    closeAction: Dispatch<SetStateAction<void>>;
    children?: ReactNode;
};

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {
    return (
        <div className="modal fade" id={props.id} role="dialog" aria-labelledby={`${props.id}Label`} aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${props.size}`} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${props.id}Label`}>
                            {props.title}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(): void => props.closeAction()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">{props.children}</div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={(): void => props.closeAction()}>
                            Chiudi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
