import React, { ReactElement } from 'react';
import { AzureAD, AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { authProvider } from './providers/auth-provider';

import { Layout } from './layout-components/_layout';
import { Loader } from './components/_shared/loader';
import { Login } from './components/auth';
import { Home } from './components/home';
import { UserSearch } from './components/user-search';
import { Files } from './components/files';
import { Export } from './components/export';
import { Report } from "./components/report";

import 'react-toastify/dist/ReactToastify.css';

export default function App(): ReactElement {
    return (
        <div id="wrapper" className="w-100 h-100">
            <AzureAD provider={authProvider} forceLogin>
                {({ authenticationState }: IAzureADFunctionProps): ReactElement => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            return (
                                <Router>
                                    <Layout>
                                        <Switch>
                                            <Route path="/user-search">
                                                <UserSearch />
                                            </Route>
                                            <Route path="/files">
                                                <Files />
                                            </Route>
                                            <Route path="/export">
                                                <Export />
                                            </Route>
                                            <Route path="/report">
                                                <Report />
                                            </Route>
                                            <Route path="/">
                                                <Home />
                                            </Route>
                                        </Switch>
                                    </Layout>
                                </Router>
                            );
                        case AuthenticationState.InProgress:
                            return (
                                <div className="bg-gradient-primary w-100 h-100">
                                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                        <div className="rounded bg-white text-center p-4">
                                            <h1 className="h4 text-gray-900 mb-1">Autenticazione in corso</h1>
                                            Verifica che la finestra di autenticazione sia visibile e inserisci i parametri di accesso
                                        </div>
                                    </div>
                                </div>
                            );

                        default:
                            return <Login />;
                    }
                }}
            </AzureAD>

            <ToastContainer
                key="ToastContainer-welcome"
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
            />

            <Loader />
        </div>
    );
}
