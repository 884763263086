import { Api } from './api';
import { SEARCH, CONTRACT } from '../config';

import partnerService from './partner';

import { User } from '../models/user';
import { Contract } from '../models/contract';

export enum UserSearchType {
    Email = 'email',
    'Ragione Sociale' = 'ragionesociale'
}

export class UserApi extends Api {
    public async search(query: string, type: UserSearchType): Promise<User[]> {
        if (!partnerService.queryId) {
            throw new Error('Nessun partner selezionato');
        }

        const result = await this.get<User[]>(SEARCH, { params: { q: query, type, partner: partnerService.queryId } });

        return result.data;
    }

    public async getContract(contractId: string, userId: string): Promise<Contract> {
        const result = await this.get<Contract>(CONTRACT, { params: { id: contractId, userid: userId } });

        return result.data;
    }
}
