import React from 'react';
import packageJson from '../../../package.json';

export const Home: React.FC = () => {
    return (
        <div>
            <h1 className="h3 mb-2 text-gray-800">Dashboard</h1>
            <p className="mb-4">Versione {packageJson.version}</p>
        </div>
    );
};
