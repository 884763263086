import React, { useState } from 'react';

import { User, UserWithRevenue } from '../../../models/user';

import { RevenueUserDetails } from './details';

type TableViewProps = {
    users: User[];
};

export const RevenueTable: React.FC<TableViewProps> = (props: TableViewProps) => {
    const realUsers = props.users as UserWithRevenue[];

    const [userView, setUserView] = useState<UserWithRevenue>();

    return (
        <>
            <div className="table-responsive">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Cod. Cliente</th>
                            <th scope="col">Email</th>
                            <th scope="col">Cognome</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Ragione sociale</th>
                            <th scope="col">Operazioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {realUsers.map(user => (
                            <tr key={user._id} className="table-row">
                                <th scope="row">{user.CodiceCliente}</th>
                                <td>{user.Emails.join(', ')}</td>
                                <td>{user.FamilyName}</td>
                                <td>{user.Name}</td>
                                <td>{user.RagioneSociale}</td>
                                <td>
                                    <button className="btn btn-primary" onClick={(): void => setUserView(user)} data-toggle="modal" data-target="#userModal">
                                        Dettaglio
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <RevenueUserDetails user={userView} closeAction={(): void => setUserView(undefined)} />
        </>
    );
};
