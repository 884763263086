import React, { useState, ChangeEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PARTNERS } from '../../config';

import partnerService from '../../services/partner';

export const LeftMenu: React.FC = () => {
    const [leftMenuVisibility, setLeftMenuVisibility] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState<string>('');
    const [logo, setLogo] = useState<string | undefined>();

    const changeLeftMenuVisibility = (): void => {
        setLeftMenuVisibility(!leftMenuVisibility);
    };

    const getCollapseClass = (): boolean | string => {
        return leftMenuVisibility ? '' : 'collapsed';
    };

    const changePartner = (event: ChangeEvent<HTMLSelectElement>): void => {
        event.preventDefault();

        const partner = PARTNERS.find(p => p.queryId === event.target.value);
        if (partner) {
            partnerService.save(partner);
        }

        window.location.href = '/';
    };

    useEffect(() => {
        if (partnerService.queryId) {
            setSelectedPartner(partnerService.queryId);
            setLogo(partnerService.logo);
        }
    }, []);

    return (
        <>
            <div className="toggle-area">
                <button className="btn btn-primary toggle-button" onClick={(): void => changeLeftMenuVisibility()}>
                    <i className="fas fa-bolt"></i>
                </button>
            </div>

            <ul className={`navbar-nav bg-gradient-primary-green sidebar sidebar-dark accordion ${getCollapseClass()}`} id="collapseMenu">
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                    <div className="sidebar-brand-icon">
                        <img src={logo || 'logo192.png'} width="80px" alt="Whishlist" />
                    </div>
                    <div className="sidebar-brand-text mx-3">Wishlist Admin</div>
                </a>

                <li className="nav-item p-2">
                    <select value={selectedPartner} className="form-control" onChange={changePartner}>
                        <option value="" disabled>
                            Partner...
                        </option>
                        {PARTNERS.map(partner => (
                            <option key={partner.queryId} value={partner.queryId}>
                                {partner.name}
                            </option>
                        ))}
                    </select>
                </li>

                {selectedPartner && (
                    <>
                        <li className="nav-item active">
                            <Link className="nav-link" to="/">
                                <i className="fas fa-fw fa-tachometer-alt"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        <hr className="sidebar-divider" />
                        <div className="sidebar-heading">Funzionalit&agrave;</div>

                        <li className="nav-item">
                            <Link className="nav-link" to="/user-search">
                                <i className="fas fa-tasks"></i>
                                <span>Interroga</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/files">
                                <i className="fas fa-cloud-upload-alt"></i>
                                <span>Importa</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/export">
                                <i className="fas fa-cloud-download-alt"></i>
                                <span>Esporta</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="/report">
                            <i className="fas fa-cloud-download-alt"></i>
                            <span>Report</span>
                        </Link>
                    </li>
                    </>
                )}
            </ul>
        </>
    );
};
