import React, { useState } from 'react';

import { User, UserWithPoints } from '../../../models/user';

import { PointsUserDetails } from './details';

type TableViewProps = {
    users: User[];
};

export const PointsTable: React.FC<TableViewProps> = (props: TableViewProps) => {
    const realUsers = props.users as UserWithPoints[];

    const [userView, setUserView] = useState<UserWithPoints>();

    return (
        <>
            <div className="table-responsive">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Email</th>
                            <th scope="col">Cognome</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Ragione sociale</th>
                            <th scope="col">Operazioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {realUsers.map(user => (
                            <tr key={user._id} className="table-row">
                                <th scope="row">{user.Emails.join(', ')}</th>
                                <td>{user.FamilyName}</td>
                                <td>{user.Name}</td>
                                <td>{user.RagioneSociale}</td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={(): void => setUserView(user)}
                                        data-toggle="modal"
                                        data-target="#userModal"
                                    >
                                        Dettaglio
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <PointsUserDetails user={userView} closeAction={(): void => setUserView(undefined)} />
        </>
    );
};
