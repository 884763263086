import React, { Fragment, Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';

import { UserWithRevenue } from '../../../models/user';
import { formatDate, formatMoney } from '../../../utils';

import { UserApi } from '../../../services/user';

import { Modal } from '../../_shared/modal';

type ModalProps = {
    user: UserWithRevenue | undefined;
    closeAction: Dispatch<SetStateAction<void>>;
};

export const RevenueUserDetails: React.FC<ModalProps> = (props: ModalProps) => {
    const [contract, setContract] = useState('');

    const api = new UserApi();

    const loadContract = async (): Promise<void> => {
        if (props.user && props.user._id && props.user.Contracts) {
            try {
                const data = await api.getContract(props.user.Contracts._id, props.user._id);
                setContract(data.Documento);
            } catch (e) {
                toast.error(e.message);
            }
        }
    };

    return (
        <>
            <Modal id="userModal" title={`Id utente: ${props.user?._id}`} size="modal-lg" closeAction={props.closeAction}>
                <div className="table-responsive">
                    <table className="table-borderless w-100">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>{props.user?.Name} {props.user?.FamilyName}</strong>
                                    <br />
                                    Email:{' '}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                props.user?.Emails.map(e => {
                                                    return `<a href="mailto:${e}">${e}</a>`;
                                                }).join(', ') || ''
                                        }}
                                    ></span>
                                    <br />
                                    Tel: {props.user?.Telefono}
                                    <div className="user-access">
                                    Iscritto in data: {formatDate(props.user?.createdAt)}
                                    <br />
                                    Ultimo accesso: {formatDate(props.user?.LastLogin)}
                                </div>
                                </td>
                                <td>
                                <strong>{props.user?.RagioneSociale}</strong>
                                    <br />
                                    P.IVA: {props.user?.PartitaIva}
                                    <br />
                                    Codice Cliente: {props.user?.CodiceCliente}
                                </td>
                            </tr>
                            <tr>                           
                                <td colSpan={2}>
                                <hr />
                                    {props.user?.Targets?.Targets?.map(target => (
                                        <>
                                            <Fragment key={target.Anno}>
                                                <h5>Anno: {target.Anno}</h5>                                               
                                                Target: <strong>€ {formatMoney(target.Target)}</strong>
                                                <br />
                                                Fatturato: <strong>€ {formatMoney(target.Fatturato)}</strong>
                                                <br />
                                                Cluster: <strong>{target.Cluster}</strong>
                                                <br />
                                                Collection: <strong>{target.IdCollection}</strong>
                                                <br />
                                                Ultimo aggiornamento:<strong> {formatDate(target.UltimoAggiornamento)}</strong>
                                                <br />
                                                Abilitazione ordine: <strong>{target.AbilitaOrdine}</strong>
                                            </Fragment>
                                            <hr />
                                        </>
                                    ))}
                                   
                                    Contratto:{' '}
                                    {props.user?.Contracts ? (
                                        <button
                                            className="btn btn-link"
                                            onClick={async (): Promise<void> => await loadContract()}
                                            data-toggle="modal"
                                            data-target="#contractModal"
                                        >
                                            Visualizza
                                        </button>
                                    ) : (
                                        'Non ancora firmato'
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>

            <Modal id="contractModal" title="Dettagli contratto" size="modal-xl" closeAction={(): void => setContract('')}>
                <span dangerouslySetInnerHTML={{ __html: contract }}></span>
            </Modal>
        </>
    );
};
