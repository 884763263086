import React, { useState, ChangeEvent, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';

import partnerService from '../../services/partner';
import { FileApi } from '../../services/file';

import { formatDateShort } from '../../utils';

export const Report: React.FC = () => {
    const [operation, setOperation] = useState<string>('');
    const [csvData, setCsvData] = useState<string>('');

    const api = new FileApi();

    const changeOperation = async (event: ChangeEvent<HTMLSelectElement>): Promise<void> => {
        event.preventDefault();

        setCsvData('');

        setOperation(event.target.value);
    };

    const exp = async (): Promise<void> => {
        try {
            const result = await api.report(operation);
            if ((result as string).length > 0) {
                setCsvData(result as string);
            }
        } catch (e) {
            toast.error(e.message);
        }
    };

    const listOperations = partnerService.operations.filter(op => op.key === "report");
    useEffect(() => {
        if (listOperations && listOperations.length === 1) {
            setOperation(listOperations[0].key);
        }
    }, [listOperations, setOperation]);

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Report</h1>
            <p className="mb-4">Console generazione report in formato CSV.</p>

            {listOperations && listOperations.length > 1 && (
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-green">Seleziona tipo di operazione:</h6>
                    </div>
                    <div className="card-body">
                        <select name="type" className="form-control" value={operation} onChange={changeOperation}>
                            <option value="" disabled>
                                Seleziona operazione...
                            </option>
                            {listOperations.map(op => (
                                <option key={op.key} value={op.key}>
                                    {op.description}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            {operation && (
                <div className="row">
                    <div className="col-xl-4 col-md-6 mb-4">
                        <div className={`card border-left-primary shadow h-100 py-2 `}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-green text-uppercase mb-1"></div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                                            {!csvData ? (
                                                <button type="button" className="btn btn-info" onClick={(): Promise<void> => exp()}>
                                                    Crea report
                                                </button>
                                            ) : (
                                                    <CSVLink data={csvData} separator=";" filename={`${partnerService.queryId}-report-${formatDateShort(new Date())}.csv`} className="btn btn-success">
                                                    Scarica il file
                                                </CSVLink>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className={`fas fa-cloud-download-alt fa-2x text-gray-300`}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
