import React, { useState, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

import { FileApi } from '../../services/file';
import { Operation } from '../../models/operation';
import { CSVValidationResult, CSVProcessResult } from '../../models/csv';

import { Modal } from '../_shared/modal';
import ToggleSwitch from '../mailswitch/switchmail.component';

type ModalProps = {
    id: string;
    filename?: string;
    closeAction: Dispatch<SetStateAction<void>>;
};

export const Import: React.FC<ModalProps> = (props: ModalProps) => {
    const [validationResult, setValidationResult] = useState<CSVValidationResult>();
    const [processResult, setProcessResult] = useState<CSVProcessResult>();
    const [sendmail, setSendMail] = useState(false);

    const api = new FileApi();

    const validate = async (): Promise<void> => {        
        try {
            const data = await api.validate(Operation.dati.key, props.filename as string);
            setValidationResult(data);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const process = async (): Promise<void> => {
        try {
            
            const data = await api.process(Operation.dati.key, props.filename as string, sendmail);
            setProcessResult(data);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const close = (): void => {
        setValidationResult(undefined);
        setProcessResult(undefined);
        props.closeAction();
    };

    const remove = async (): Promise<void> => {
        try {
            const data = await api.processDelete(Operation.delete.key, props.filename as string);
            if (data) {                
                toast.success('File eliminato con successo');
            }
            
        } catch (e) {
            toast.error(e.message);
        }
    };

    
    return (
        <Modal id={props.id} title={`Validazione ${props.filename}`} closeAction={(): void => close()}>
            <div className="d-flex">
            <ToggleSwitch
                    setCheck={setSendMail}
                    checked={ sendmail }/>
            </div>
            <div className="d-flex">
                <button className="btn btn-danger w-100 mr-1" onClick={(): Promise<void> => remove()}>
                    Elimina
                </button>
                <button className="btn btn-primary w-100 ml-1 mr-1" onClick={(): Promise<void> => validate()}>
                    Verifica
                </button>
                <button className="btn btn-primary w-100 ml-1" onClick={(): Promise<void> => process()}>
                    Importa
                </button>
            </div>
            {validationResult && (
                <>
                    <table className="table-borderless">
                        <tbody>
                            <tr>
                                <th>Record totali</th>
                                <td className="px-4 py-2">{validationResult.RecordTotali}</td>
                            </tr>
                            <tr>
                                <th>Record importabili</th>
                                <td className="px-4 py-2">{validationResult.RecordImportabili}</td>
                            </tr>
                            <tr>
                                <th>Record con errori</th>
                                <td className="px-4 py-2">{validationResult.RecordConErrori}</td>
                            </tr>
                        </tbody>
                    </table>
                    {validationResult.RecordConErrori > 0 && (
                        <>
                            <button
                                className="btn btn-danger w-100 mt-2"
                                type="button"
                                data-toggle="collapse"
                                data-target="#errorCollapse"
                                aria-expanded="false"
                                aria-controls="errorCollapse"
                            >
                                Visualizza errori
                            </button>
                            <div className="collapse mt-2" id="errorCollapse">
                                {validationResult.Errori && validationResult.Errori.length > 0 && (
                                    <ul>
                                        {validationResult.Errori.map(error => (
                                            <li key={error.row}>
                                                {error.row}: {error.message}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}

            {processResult && (
                <div className="mt-2">
                    <b>Record importati:</b> {processResult?.RecordImportati}
                    {processResult.Errori && processResult.Errori.length > 0 && (
                        <ul>
                            {processResult.Errori.map((error, i) => (
                                <li key={i}>{error}</li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </Modal>
    );
};
