import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UserSearchType, UserApi } from '../../services/user';
import partnerService from '../../services/partner';

import { User } from '../../models/user';

import { PartnerFactory } from '../../models/partner';

import { RevenueTable } from './revenue/table';
import { PointsTable } from './points/table';

export const UserSearch: React.FC = () => {
    const { register, handleSubmit, errors } = useForm<{ query: string; type: string }>();

    const [users, setUsers] = useState<User[]>();

    const api = new UserApi();

    const onSubmit = async (data: { query: string; type: string }): Promise<void> => {
        try {
            const results = await api.search(data.query, data.type as UserSearchType);
            setUsers(results);
        } catch (e) {
            toast.error(e.message);
        }
    };

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Cerca movimenti</h1>
            <p className="mb-4">Ricerca per email</p>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-green">Ricerca</h6>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-md-9">
                                <input
                                    type="text"
                                    name="query"
                                    placeholder="Ricerca per email"
                                    className="form-control"
                                    ref={register({ required: true })}
                                />
                                {errors.query && <div className="text-danger py-2">Inserisci un valore per la ricerca</div>}
                            </div>
                            <div className="form-group col-md-3">
                                <select name="type" className="form-control" ref={register({ required: true })}>
                                    {Object.entries(UserSearchType).map(([key, value]) => (
                                        <option key={key} value={value}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                                {errors.type && <div className="text-danger py-2">Seleziona un&apos;opzione</div>}
                            </div>
                        </div>
                        <button type="submit" className="btn btn-success">
                            Cerca
                        </button>
                    </form>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-green">Risultati</h6>
                            <div className="header-buttons"></div>
                        </div>
                        <div className="card-body">
                            {users &&
                                (users.length > 0 ? (
                                    partnerService.factory === PartnerFactory.Revenue ? (
                                        <RevenueTable users={users} />
                                    ) : (
                                        <PointsTable users={users} />
                                    )
                                ) : (
                                    <h4>Nessun risultato con i termini di ricerca inseriti</h4>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
