import qs from 'qs';
import { PathLike } from 'fs';

import { BACKEND_BASE_URL } from '../config';

export const apiConfig = {
    returnRejectedPromiseOnError: true,
    // withCredentials: false,
    timeout: 30000,
    baseURL: BACKEND_BASE_URL,
    // headers: {
    //     common: {
    //         'Cache-Control': 'no-cache, no-store, must-revalidate',
    //         Pragma: 'no-cache',
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json'
    //     }
    // },
    paramsSerializer: (params: PathLike): string => qs.stringify(params, { indices: false })
};
