import React, { useState, ReactElement } from 'react';
import { AzureAD, IAzureADFunctionProps } from 'react-aad-msal';

import { authProvider } from '../../providers/auth-provider';

export const TopMenuAccount: React.FC = () => {
    const [isShow, setShow] = useState(false);

    return (
        <li className="nav-item dropdown no-arrow">
            <AzureAD provider={authProvider}>
                {({ accountInfo, logout }: IAzureADFunctionProps): ReactElement => {
                    return (
                        <>
                            <button
                                className="btn nav-link dropdown-toggle"
                                onClick={(): void => setShow(!isShow)}
                                id="userDropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span className="mr-2 d-none d-lg-inline small">{accountInfo?.account.name || accountInfo?.account.userName}</span>
                                <img className="img-profile rounded-circle" alt="" src="./logo192.png" />
                            </button>

                            <div
                                className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${isShow ? 'show' : ''}`}
                                aria-labelledby="userDropdown"
                            >
                                <button className="dropdown-item" onClick={(): void => logout()} data-toggle="modal" data-target="#logoutModal">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </button>
                            </div>
                        </>
                    );
                }}
            </AzureAD>
        </li>
    );
};
