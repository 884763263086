import { Partner, PartnerFactory } from './models/partner';
import { Operation } from './models/operation';
import { AzureConfig } from './models/azure-config';

export const PARTNERS: Partner[] = [
    {
        name: 'DLINK',
        queryId: 'DLINK',
        operations: [Operation.list, Operation.dati, Operation.report, Operation.export],
        factory: PartnerFactory.Point,
        image: 'https://www.dlink.com/-/media/feature/identity/logo-238x238.jpg'
    },
    {
        name: 'IDEMA',
        queryId: 'IDEMA',
        operations: [Operation.list, Operation.dati, Operation.report, Operation.export],
        factory: PartnerFactory.Revenue,
        image: 'https://www.idemaclima.com/images/idemaclima/367C/IDEMA_150x100_verde.png'
    }
];


export const BACKEND_BASE_URL = 'https://wlbackoffice.azurewebsites.net'; 
// export const BACKEND_BASE_URL = 'http://localhost:7071';
// export const BACKEND_BASE_URL = 'https://wlbackoffice-wlbackoffice-staging.azurewebsites.net';
export const FILE_LIST = '/api/file';
export const SAS_SIGNATURE = '/api/sas-signature';
export const VERIFY_CSV = '/api/readandprocess-csv';
export const IMPORT_CSV = '/api/importandprocess-csv';
export const EXPORT_CSV = '/api/export';
export const SEARCH = '/api/user';
export const CONTRACT = '/api/contract';

// AUTENTICAZIONE
const CLIENT_ID = 'd93340e1-0f2f-4be1-bab7-4873783c71bf';
const LOGIN_REDIRECT_URI = 'https://wishlistbo.refactormi.com/';
const LOGIN_REDIRECT_URI_DEV = 'http://localhost:3000'; // Solo per DEV
const AUTHORITY_LOGIN = 'https://login.microsoftonline.com/cd4fff5b-ce0f-4037-89fe-0224d46ef7ff/';

export function getAuthorityConf(): AzureConfig {
    return {
        LOGIN_REDIRECT_URI: process.env.NODE_ENV !== 'production' ? LOGIN_REDIRECT_URI_DEV : LOGIN_REDIRECT_URI,
        AUTHORITY_LOGIN: AUTHORITY_LOGIN,
        CLIENT_ID: CLIENT_ID
    };
}
