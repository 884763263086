import { Partner, PartnerFactory } from '../models/partner';
import { Operation } from '../models/operation';

class PartnerService {
    private PARTNER_KEY = 'bo_partner';
    private partner = {} as Partner;

    constructor() {
        const store = sessionStorage.getItem(this.PARTNER_KEY);

        if (store) {
            this.partner = JSON.parse(store) as Partner;
        }
    }

    public get queryId(): string {
        return this.partner.queryId;
    }

    public get factory(): PartnerFactory {
        return this.partner.factory;
    }

    public get operations(): Operation[] {
        return this.partner.operations;
    }

    public get logo(): string | undefined {
        return this.partner.image;
    }

    save(partner: Partner): void {
        this.partner = partner;
        sessionStorage.setItem(this.PARTNER_KEY, JSON.stringify(this.partner));
    }
}
const partnerService = new PartnerService();
export default partnerService;
