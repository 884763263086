import React, { ReactElement } from 'react';
import { AzureAD, AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';

// Import the authentication provider which holds the default settings
import { authProvider } from '../../providers/auth-provider';

// Change the login type to execute in a Popup
// const options = authProvider.getProviderOptions();
// options.loginType = LoginType.Popup;
// authProvider.setProviderOptions(options);

export const LoginButton: React.FC = () => {
    return (
        <AzureAD provider={authProvider}>
            {({ login, logout, authenticationState }: IAzureADFunctionProps): ReactElement => {
                const isInProgress = authenticationState === AuthenticationState.InProgress;
                const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                if (isAuthenticated) {
                    return (
                        <div className="text-center">
                            <h1 className="h5 text-gray-900 mt-4">Accesso già effettuato</h1>
                            <button className="btn btn-primary btn-user btn-block mt-2" onClick={logout}>
                                Logout
                            </button>
                        </div>
                    );
                } else {
                    return (
                        <button className="btn btn-primary btn-user btn-block mt-2" onClick={login} disabled={isInProgress}>
                            Accedi
                        </button>
                    );
                }
            }}
        </AzureAD>
    );
};
